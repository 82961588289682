import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">
        <p parentName="li">{`UNI, the Uniswap Protocol token, is live!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`UNI contract address: `}<a parentName="p" {...{
            "href": "https://etherscan.io/token/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
          }}>{`0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`60% of the UNI genesis supply is allocated to Uniswap community members, a quarter of which (15% of total supply) has already been distributed to past users`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To start, UNI is available through four liquidity mining pools: UNI holders may vote to add more pools after an initial 30-day governance grace period`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Community-led Growth`}</strong></p>
    <p>{`Since its inception, the Uniswap Protocol (`}{`”`}{`Uniswap`}{`”`}{`) has served as trustless and highly decentralized financial infrastructure.`}</p>
    <p><a parentName="p" {...{
        "href": "https://uniswap.org/blog/uniswap-history/#ethereum-values"
      }}>{`Inspired by Ethereum`}{`’`}{`s vision`}</a>{`, we have long committed to the ideals of permissionless access, security, and immutability, all indespensable components for a future where anyone in the world can access financial services without fear of discrimination or counterparty risk.`}</p>
    <p>{`Now rivaling centralized incumbents on daily volume, Uniswap`}{`’`}{`s success to date — achieved without involvement of the core development team since deployment — indicates that there is considerable demand for permissionless financial services.`}</p>
    <p>{`In less than two years, the protocol has:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Supported over `}<strong parentName="p">{`$`}{`20bn`}</strong>{` volume (`}{`$`}{`270k of which was socks!) traded by over `}<strong parentName="p">{`250,000`}</strong>{` unique addresses across `}<strong parentName="p">{`8,484`}</strong>{` unique assets`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Secured over `}<strong parentName="p">{`$`}{`1bn`}</strong>{` liquidity deposited by over `}<strong parentName="p">{`49,000`}</strong>{` unique liquidity providers (LPs), earning `}<strong parentName="p">{`$`}{`56m`}</strong>{` fees in the process`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Emerged as foundational DeFi infrastructure, with integrations across `}<strong parentName="p">{`hundreds`}</strong>{` of interfaces and applications`}</p>
      </li>
    </ul>
    <p>{`Having proven product-market fit for highly decentralized financial infrastructure with a platform that has thrived independently, `}<strong parentName="p">{`Uniswap is now particularly well positioned for community-led growth, development, and self-sustainability`}</strong>{`. The introduction of UNI (ERC-20) serves this purpose, enabling shared community ownership and a vibrant, diverse, and dedicated governance system, which will actively guide the protocol towards the future.`}</p>
    <p>{`Uniswap has always embraced the tenets of `}<strong parentName="p">{`neutrality and trust minimization`}</strong>{` : it is crucial that governance is constrained to where it is strictly necessary. With this in mind, the Uniswap governance framework is limited to contributing to both protocol development and usage as well as development of the broader Uniswap ecosystem.`}</p>
    <p>{`In doing so, `}<strong parentName="p">{`UNI officially enshrines Uniswap as publicly-owned and self-sustainable infrastructure while continuing to carefully protect its indestructible and autonomous qualities`}</strong>{`.`}</p>
    <p><strong parentName="p">{`UNI Allocation`}</strong></p>
    <p>{`1 billion UNI have been minted at genesis and will become accessible over the course of 4 years. The initial four year allocation is as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`60.00% to Uniswap community members `}<inlineCode parentName="p">{`600,000,000 UNI`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`21.266% to team members and future employees with 4-year vesting `}<inlineCode parentName="p">{`212,660,000 UNI`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`18.044% to investors with 4-year vesting `}<inlineCode parentName="p">{`180,440,000 UNI`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`0.69% to advisors with 4-year vesting `}<inlineCode parentName="p">{`6,900,000 UNI`}</inlineCode></p>
      </li>
    </ul>
    <p>{`A `}<strong parentName="p">{`perpetual inflation rate of 2% per year will start after 4 years`}</strong>{`, ensuring continued participation and contribution to Uniswap at the expense of passive UNI holders.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3c44e284f8901d2da9e32463625c51fc/35b4f/Genesis.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACNUlEQVQ4y2WTz0sVURTHx4J+LGrTP9HfEAjRok1rFxGB2yiijMiszEg3tioipV3lokWtwoVhBAZBZUYkqGnPn/XSfKjznvP7nk/nzrx589SBw5m5c8/nfs+P66yulE1pZo6l0oLMz/5ifrZE6ecc3lYNYsGECRIa8GMkSEC/iUzhd5kTVD22N6tiAeoblvgRkm9MFEr2iIItTOpQabIUqCoEfcfGZD41CTQwSvjrJ/yYq5C8noQVNwM3AaRJnV1zZNcp6UY1r+ozVHJpn6rSO7wIThdypAfpH8uk5nuD3QqbT1BFtmYkhmcrNdq+VDg36dI3sgQH7yj0BuJcgQcfMqUKC/9t465vEdb8ApifkMKMYaYcc37U5cLEBu3TW9x/twwHFNiiKhXKsV6kXE2FVjYC/iyvEm0HexXGtosYXn2LaX0c0DbkcXbUo/v9GnKoWxXeRFpuqe9EhqdTYLDp41ZcYi/cqzAJFEzMy68hrQM+ZwY9TjyHpxc/K+Q6xirMVb6Zymppu55I0ZSdKdtRCJiYDzg96HPyheFS/2+iwz1Z/fbfVlgnHL1HMLtGFMU7RidTmEKKtmdzZ7g7EnHqYY3F409SdYmtoU3ZuQxdb7G5iBcVcXWOAlVvmC6K/Zk2JoxZ1yzG+8YVcg3jdKjvQPZp/a4OIzbF2OyYxwKYNUTysWlAbYCmZMYW4NFHZOAT8r2cjUtcL1PTuDWuXq7Q+mKwTXpv05ra1OrWaEKQp1i/22Gh8D/BQ7Lk4k9P3QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Genesis",
            "title": "Genesis",
            "src": "/static/3c44e284f8901d2da9e32463625c51fc/c1b63/Genesis.png",
            "srcSet": ["/static/3c44e284f8901d2da9e32463625c51fc/5a46d/Genesis.png 300w", "/static/3c44e284f8901d2da9e32463625c51fc/0a47e/Genesis.png 600w", "/static/3c44e284f8901d2da9e32463625c51fc/c1b63/Genesis.png 1200w", "/static/3c44e284f8901d2da9e32463625c51fc/d61c2/Genesis.png 1800w", "/static/3c44e284f8901d2da9e32463625c51fc/97a96/Genesis.png 2400w", "/static/3c44e284f8901d2da9e32463625c51fc/35b4f/Genesis.png 4524w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/86bcb8c1b0370519e41ef91da019f9f4/afec5/Inflation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACPklEQVQ4y22Ty2sTURTGpz5XXfm/iDtBUBcuXYgIupEuS3WjNVHU1o3uBLvpwoWidqFgUUQXxQcIgoWA1ZjWNG2mJtI2bZJ53MnMvefz3HuTySQ05HCSO2d+9zsvp7m5o9aWV7FRqdJq6Q/WVipwK1VUlstYKZZQd2ugjgLCBBRJoKNNZfygObEfIdj1SLQC7RE2fYQtHwF7j/8rLwQSBQ4HiL8MBoPNJdpnDBEDEROBYxDD+q6R4AARYzMiLJV3IF8uAW5bM1OQVkkZdfrMoaFbbKCC7CR4Wm7j0q82pt6tA84N0Oht0P3PQA+qQUEMGbKJxKbcI5O+LVIp8HFR4PxiAxd+tjD9wQUO32LoNZAzATz4kir16j62aw1EnugDde7aKw1TCr//SpyeFbg47+PyjxamFlzQoZvACKtkKI5Mg2oedGF3mxG2/jWMykGFbFJosMDc9wjHHwmcmQlx8jUhv7DNCvOscBI0kmPoddCbksk8buqm+nsDE90ISjC32MHRWYkTz4Hxu1UUjj1hGEN0HVmlTh3zRZu27npM/abslXLBlRi7t4GPp14BB3MMuGpg2Jez4NE7oPWmSVlPQ7aZQ03RoyLNzCXnXvDLYwYkdf0O5Pn3JJ+NgybfQxC/FySpsnSweZbIKIwUmYc6bT7CVgB59pmpF5wr7FnlflY48ZarQgOqekNtB9uSLay7TiYNZceNPlVAD78CM9+AQt3Wrbslw+r6Cu1uktnPXoCw6Wc/vSZQd6hThZm9/g+bz69hgoIiLgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Inflation",
            "title": "Inflation",
            "src": "/static/86bcb8c1b0370519e41ef91da019f9f4/c1b63/Inflation.png",
            "srcSet": ["/static/86bcb8c1b0370519e41ef91da019f9f4/5a46d/Inflation.png 300w", "/static/86bcb8c1b0370519e41ef91da019f9f4/0a47e/Inflation.png 600w", "/static/86bcb8c1b0370519e41ef91da019f9f4/c1b63/Inflation.png 1200w", "/static/86bcb8c1b0370519e41ef91da019f9f4/d61c2/Inflation.png 1800w", "/static/86bcb8c1b0370519e41ef91da019f9f4/97a96/Inflation.png 2400w", "/static/86bcb8c1b0370519e41ef91da019f9f4/afec5/Inflation.png 4526w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`A Retrospective`}</strong></p>
    <p>{`Uniswap owes its success to the thousands of community members that have joined its journey over the past two years. These early community members will naturally serve as responsible stewards of Uniswap.`}</p>
    <p>{`15% of UNI `}<em parentName="p"><inlineCode parentName="em">{`150,000,000 UNI`}</inlineCode></em>{` can immediately be claimed by `}<strong parentName="p">{`historical liquidity providers, users, and SOCKS redeemers/holders`}</strong>{` based on a snapshot ending September 1, 2020, at 12:00 am UTC.`}</p>
    <p><em parentName="p">{`4.91664% pro-rata to all `}<strong parentName="em">{`49,192`}</strong>{` historical LPs `}<inlineCode parentName="em">{`49,166,400 UNI`}</inlineCode></em></p>
    <p><strong parentName="p">{`~49 million UNI`}</strong>{` are claimable by historical liquidity providers. The formula accounts for LP liquidity on a per-second basis since the deployment of Uniswap v1, ensuring that rewards are weighted towards LPs that provided liquidity when total liquidity was low.`}</p>
    <p><em parentName="p">{`10.06136% split evenly across all `}<strong parentName="em">{`251,534`}</strong>{` historical user addresses `}<inlineCode parentName="em">{`100,613,600 UNI`}</inlineCode></em></p>
    <p>{`400 UNI are claimable by each address that has ever called the Uniswap v1 or v2 contracts. This includes `}<strong parentName="p">{`~12,000`}</strong>{` addresses that have only ever submitted failed transactions — love you guys.`}</p>
    <p><em parentName="p">{`0.022% to `}<strong parentName="em">{`220`}</strong>{` SOCKS holders/redeemers `}<inlineCode parentName="em">{`220,000 UNI`}</inlineCode></em></p>
    <p>{`1000 UNI are claimable by each address that has either redeemed SOCKS tokens for physical socks or owned at least one SOCKS token at the snapshot date.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/Uniswap/retroactive-query"
      }}>{`View the formula source code here`}</a></p>
    <p><strong parentName="p">{`Community Treasury`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ef51d794d59869134d3d393b80cc14ad/35b4f/Release_Schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACf0lEQVQ4y42Ty0tVQRzHT0otWgetW/QvtOixETeZkbgojBYWQbQJMlNT0WpjUNALkhY+SuiS10WUmyCTiEKMTK9JmV2uD24+7zl13jNnznz7zZxrqbRo8eE38zvz+/CbOTPGan45ns/OYiE7J3PTWWS/zmDu+yy+TU1jaeEHwGPIUECyBBSjysWEUJHFlE8wXNOGvfZT+r9ceJYDl1Bj17IROn4iZH+FcRGVR1RE5XyB0I1ghE4gucvoAySBTXC5qRvdhRKQzHYifFwOMDjvonvGRtukia6sTUI7AHMCvVhvbQtiXUQS5glkVgN052zUTZmoHSug5kMBp0ZNHH9r4faEC0PJNgrBtookXC/Ci7yLZpKcHiXJsIkTgzaqB3wcS4U40sdxsEug5VWst/xHqM9Cd5Z0FIURhhddNIxbqBpyUPE8RHla4lAKKOuJUdHpo+q+jZN3TZxtn0fvo/y6MNRCEVBXujOOkTUf9ZMeyl9zlKVjVD9wce56Hh3nx5Cqeon3+/qQ23MP5q4bYDuvIjYagMrHyRkq4NNf4AKmkOjIxah85qH21hLunBnF0IF+rOy+CZQ0A6rQuEjUEZeJRuIKUQ+phIHtQ3Up6aeOWEB7ahGdNcP4svchoh1temEiaNCF8bZmiJJWooXGLZA0l6UUSS6PrgstDzM9n/Fufx/s7deo8FJRRAIqFKWtOurif7FRyCMhWWaREo0SxgXdSWw0aaTezv/QlBzB4V4YUJd+xZFsIIMgnUHYPwH2lKBxlJ4Ep3lUhFNOwfSa8SITCNX6J5/A32Rh0J2Tgq4HeSWhImL1SOj12L6HAuewIoFCxOBwBtPz4dMqUXxMG6PiN0RAagbcjqJmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Release Schedule",
            "title": "Release Schedule",
            "src": "/static/ef51d794d59869134d3d393b80cc14ad/c1b63/Release_Schedule.png",
            "srcSet": ["/static/ef51d794d59869134d3d393b80cc14ad/5a46d/Release_Schedule.png 300w", "/static/ef51d794d59869134d3d393b80cc14ad/0a47e/Release_Schedule.png 600w", "/static/ef51d794d59869134d3d393b80cc14ad/c1b63/Release_Schedule.png 1200w", "/static/ef51d794d59869134d3d393b80cc14ad/d61c2/Release_Schedule.png 1800w", "/static/ef51d794d59869134d3d393b80cc14ad/97a96/Release_Schedule.png 2400w", "/static/ef51d794d59869134d3d393b80cc14ad/35b4f/Release_Schedule.png 4524w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`With 15% of tokens already available to be claimed by historical users and liquidity providers, the governance treasury will retain 43% `}{`[430,000,000 UNI]`}{` of UNI supply to distribute on an ongoing basis through `}<strong parentName="p">{`contributor grants, community initiatives, liquidity mining`}</strong>{`, and other programs.`}</p>
    <p>{`UNI will vest to the governance treasury on a continuous basis according to the following schedule. Governance will have access to vested UNI starting October 18 2020 12:00am UTC.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Year`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Community Treasury`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Distribution %`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`172,000,000 UNI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`129,000,000 UNI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86,000,000 UNI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43,000,000 UNI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10%`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Team, investor, and advisor UNI allocations will have tokens locked up on an identical schedule.`}</p>
    <p><strong parentName="p">{`Liquidity Mining`}</strong></p>
    <p>{`An initial liquidity mining program will go live September 18 2020 12:00am UTC. The initial program will run until November 17 2020 12:00am UTC and target the following four pools on Uniswap v2:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`ETH/USDT`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ETH/USDC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ETH/DAI`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ETH/WBTC`}</p>
      </li>
    </ul>
    <p>{`5,000,000 UNI will be allocated per pool to LPs proportional to liquidity, which roughly translates to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`83,333 UNI per pool per day`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`13.5 UNI per pool per block (14s blocktime)`}</p>
      </li>
    </ul>
    <p>{`These UNI are not subject to vesting or lock up.`}</p>
    <p>{`After 30 days, governance will reach its vesting cliff and Uniswap governance will control all UNI vested to the Uniswap treasury. At this point, governance can vote to allocate UNI towards grants, strategic partnerships, governance initiatives, additional liquidity mining pools, and other programs.`}</p>
    <p><strong parentName="p">{`Governance assets`}</strong></p>
    <p>{`A community-managed treasury opens up a world of infinite possibilities. We hope to see a variety of experimentation, including ecosystem grants and public goods funding, both of which can foster additional Uniswap ecosystem growth. Uniswap has set the standard for automated liquidity provision: it is now time to set the benchmark for responsible but radical, long-term aligned on-chain governance systems.`}</p>
    <p>{`Uniswap governance will be live from day one, although control over the treasury will be delayed until October 17 2020 12:00am UTC. Control over the Uniswap fee switch is subject to a 180 day time lockdelay.`}</p>
    <p>{`These grace periods provide the Uniswap community enough time to familiarize itself with the governance system, bring in `}<strong parentName="p">{`a diverse and high-quality set of protocol delegates,`}</strong>{` and begin discussions and communications around potential governance proposals.`}</p>
    <p>{`UNI holders are responsible for ensuring that governance decisions are made in compliance with applicable laws and regulations. To help facilitate this, the fee switch has been initialized to a contract UNI holders can use to vote on tokens for which they will collect fees. The community is encouraged to consult knowledgeable legal and regulatory professionals before implementing any specific proposal.`}</p>
    <p>{`The Uniswap team will continue to have no involvement in v2 protocol development, auditing, and other matters. Similarly, team members will not participate directly in governance for the foreseeable future, although they may delegate votes to protocol delegates without seeking to influence their voting decisions.`}</p>
    <p>{`In the meantime, UNI holders will have immediate ownership of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Uniswap governance`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`UNI community treasury`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The protocol fee switch`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`uniswap.eth ENS name`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uniswap Default List (tokens.uniswap.eth)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://twitter.com/Uniswap/status/1126506339075641344?s=20"
          }}>{`SOCKS`}</a>{` liquidity tokens`}</p>
      </li>
    </ul>
    <p>{`Initial governance parameters are as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`1% of UNI total supply (delegated) to submit a governance proposal`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`4% of UNI supply required to vote `}{`’`}{`yes`}{`’`}{` to reach quorum`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`7 day voting period`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2 day timelock delay on execution`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Next steps`}</strong></p>
    <p>{`All historical users, liquidity providers, and SOCKS redeemers can `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/"
      }}>{`claim their UNI now`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/3f335cd45a52ada3ee456c64a12f680a/claim.gif",
        "alt": null
      }}></img></p>
    <p>{`Liquidity providers in the preliminary set of targeted pools can `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/#/uni"
      }}>{`deposit`}</a>{` their liquidity tokens. Mining will begin on September 18th 2020 12am UTC.`}</p>
    <p><img parentName="p" {...{
        "src": "/c65fc9e83b687f4291c4907274029da3/deposit.gif",
        "alt": null
      }}></img></p>
    <p>{`UNI tokens can be delegated and used to vote through the `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/#/vote"
      }}>{`governance portal`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/15db61d89ad5764f98485102d41b708d/vote.gif",
        "alt": null
      }}></img></p>
    <p>{`Set up an account on `}<a parentName="p" {...{
        "href": "https://gov.uniswap.org"
      }}>{`gov.uniswap.org`}</a>{` to start shaping and participating in protocol governance`}</p>
    <p><strong parentName="p">{`Contracts`}</strong></p>
    <p>{`UNI Token:`}</p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984"
      }}>{`https://etherscan.io/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984`}</a></p>
    <p>{`Liquidity mining:`}</p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/address/0x6c3e4cb2e96b01f4b866965a91ed4437839a121a"
      }}>{`Tether Liquidity Mining Pool`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/address/0x7FBa4B8Dc5E7616e59622806932DBea72537A56b"
      }}>{`USDC Mining Pool`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/address/0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711"
      }}>{`DAI Mining Pool`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/address/0xCA35e32e7926b96A9988f61d510E038108d8068e"
      }}>{`WBTC Mining Pool`}</a></p>
    <p>{`Governance:`}</p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F"
      }}>{`https://etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F`}</a></p>
    <p>{`Timelock:`}</p>
    <p><a parentName="p" {...{
        "href": "https://etherscan.io/address/0x1a9c8182c09f50c8318d769245bea52c32be35bc"
      }}>{`https://etherscan.io/address/0x1a9c8182c09f50c8318d769245bea52c32be35bc`}</a></p>
    <p>{`P.S. `}<a parentName="p" {...{
        "href": "https://logo.uniswap.org"
      }}>{`Uniswap Token icon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      